export const win = `
# Enter Giveaway
<img id="image-full" src="/assets/giveaway/2024/September/giveaway.png" />
</br></br>
<div id="logged-in">
<a style="text-align:center;" href="/account/giveaway" id="button">Enter Giveaway</a>
</div>
<div style="text-align:center;" id="logged-out">
<a href="/account/login" id="button">Sign In</a>
<a href="/account/register" id="button">Register</a>
<a href="/account/giveaway" id="button">Enter as Guest</a>
</div>

<hr \>
</div>
`
// ## Prize – American Flag flown aboard C-47 Whiskey-7
// </br></br>
// <p>Whiskey 7 is a legendary military transport aircraft with a rich history. It played a pivotal role in World War II, notably during the D-Day invasion in 1944, when it dropped paratroopers behind enemy lines. This iconic aircraft is celebrated for its ruggedness and versatility, and its enduring legacy is a testament to its contribution to the Allied victory in the war. Today, Whiskey 7 serves as a symbol of the bravery and sacrifice of the men and women who flew and maintained these aircraft during one of the most significant conflicts in history.
// </p>
// </br></br>
// <div style="margin: 0 auto; text-align: center;">
// <img src="/assets/giveaway/2023/operation-give-thanks/ogt-case.png" />
// </br></br>
// ### Coming Soon!
// <img id="image-full" src="/assets/giveaway/2023/back-to-school/weekly-giveaway.png" />
// </br></br>
// ### Grand Prize 1 HP All In One
// <img id="image-full" src="/assets/giveaway/2023/back-to-school/grand-prize-1.png" />
// </br></br>
// ### Grand Prize 3 Sony Vaio
// <img id="image-full" src="/assets/giveaway/2023/back-to-school/grand-prize-2.png" />
// </br></br>
// ### Grand Prize 3 Surface Pro
// <img id="image-full" src="/assets/giveaway/2023/back-to-school/grand-prize-3.png" />
// </br></br>